<template>
  <b-modal
    title="Confirm A Cashout"
    id="confirm-a-cashout-modal"
    centered
    ok-title="Confirm"
    cancel-title="Dismiss"
    :ok-disabled="isLoading"
    ok-variant="success"
    no-close-on-ok
    @ok.prevent="okCallback()"
  >
    <b-overlay :show="isLoading">
      <b-container fluid>
        <b-form-row>
          <b-col cols="12" v-if="tempRequest">
            <p>
              Are you sure you want to approve cashout of
              <span style="font-weight: bold !important">
                {{ Intl.NumberFormat("en-US").format(tempRequest.price) }} $
              </span>
              ?
            </p>
          </b-col>
        </b-form-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  props: {
    tempRequest: {
      type: [Object, null],
    },
    okCallback: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BOverlay,
    BModal,
    BContainer,
    BCol,
    BFormRow,
    BFormGroup,
    BFormTextarea,
    BButton,
  },
};
import {
  BOverlay,
  BModal,
  BContainer,
  BCol,
  BFormRow,
  BFormGroup,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
</script>
