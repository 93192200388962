import WebServiceRequest from "./WebServiceRequest";

class FakeNameGetAllRequest extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("FakeName/GetAll");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    FakeNameGetAllRequest,
}