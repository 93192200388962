<template>
  <b-modal
    title="Create Fake names"
    id="create-new-fake-names"
    centered
    ok-title="Create"
    cancel-title="Dismiss"
    :ok-disabled="isLoading"
    no-close-on-backdrop
    ok-variant="success"
    no-close-on-ok
    @hidden="resetModal"
    @ok.prevent="okCallback(file)"
  >
    <b-overlay :show="isLoading">
      <b-container fluid>
        <b-form-row>
          <b-col cols="12">
            <b-alert
              show
              variant="warning"
              class="px-2 py-1 d-flex flex-column align-items-center justify-content-center"
            >
              <h3 class="alert-heading">Example EXCEL Empty Template:</h3>
              <BButton download href="/template.xlsx" variant="success"
                >Download Template</BButton
              >
            </b-alert>
          </b-col>
          <b-col cols="12" class="mt-2">
            <strong>Select your excel file</strong>
          </b-col>
          <b-col cols="12" class="mt-2">
            <input
              type="file"
              accept=".xls,.xlsx"
              ref="input"
              @change="setFile($event)"
            />
          </b-col>
        </b-form-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
import TextInput from "@/views/components/Utilities/TextInput.vue";
import CustomAppendText from "@/views/components/Utilities/CustomAppendText.vue";
import {
  BModal,
  BOverlay,
  BContainer,
  BCol,
  BFormRow,
  BFormGroup,
  BButton,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  props: {
    okCallback: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    setFile(e) {
      this.file = e.target.files[0];
    },
    resetModal() {
      this.file = null;
    },
  },
  components: {
    BModal,
    BOverlay,
    BContainer,
    BCol,
    BFormRow,
    BFormGroup,
    BButton,
    TextInput,
    CustomAppendText,
    BAlert,
    vSelect,
  },
};
</script>
