<template>
  <b-modal
    title="Create a new bonus"
    id="create-new-bonus"
    centered
    ok-title="Create"
    cancel-title="Dismiss"
    :ok-disabled="isLoading"
    no-close-on-backdrop
    ok-variant="success"
    no-close-on-ok
    @hidden="resetModal"
    @ok.prevent="okCallback(bonusPayload)"
  >
    <b-overlay :show="isLoading">
      <b-container fluid>
        <b-form-row>
          <b-col cols="12">
            <text-input
              v-model="bonusPayload.title"
              inputId="bonusTitle"
              inputLabel="Bonus Title"
              inputPlaceholder="Enter your bonus title"
            ></text-input>
          </b-col>
          <b-col cols="12">
            <text-input
              v-model="bonusPayload.fromPrice"
              inputId="bonusFromPrice"
              inputLabel="Bonus Start Price (From)"
              inputPlaceholder="Enter your bonus start price"
            ></text-input>
          </b-col>
          <b-col cols="12">
            <text-input
              v-model="bonusPayload.toPrice"
              inputId="bonusToPrice"
              inputLabel="Bonus End Price (To)"
              inputPlaceholder="Enter your bonus End price"
            ></text-input>
          </b-col>
          <b-col cols="12">
            <custom-append-text
              :isText="true"
              v-model="bonusPayload.price"
              inputId="bonusPricePercent"
              inputLabel="The amount of bonus percent (%)"
              inputPlaceholder="Enter your bonus End price"
            >
              <template #append> % </template>
            </custom-append-text>
          </b-col>
          <b-col cols="12">
            <text-input
              v-model="bonusPayload.cashPerTradeRate"
              inputId="bonusCashPerTradeRate"
              inputLabel="The rate of cash bonus per trade"
              inputPlaceholder="Enter The rate of cash bonus per trade"
            >
            </text-input>
          </b-col>
        </b-form-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  props: {
    okCallback: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      bonusPayload: {
        id: null,
        title: null,
        fromPrice: null,
        toPrice: null,
        price: null,
        cashPerTradeRate: null,
      },
    };
  },
  methods: {
    resetModal() {
      this.bonusPayload = {
        id: null,
        title: null,
        fromPrice: null,
        toPrice: null,
        price: null,
        cashPerTradeRate: null,
      };
    },
  },
  components: {
    BModal,
    BOverlay,
    BContainer,
    BCol,
    BFormRow,
    BFormGroup,
    BButton,
    TextInput,
    CustomAppendText,
  },
};
import TextInput from "@/views/components/Utilities/TextInput.vue";
import CustomAppendText from "@/views/components/Utilities/CustomAppendText.vue";
import {
  BModal,
  BOverlay,
  BContainer,
  BCol,
  BFormRow,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
</script>
