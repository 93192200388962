<template>
  <b-form-group
    class="d-inline-block mr-md-1 w-24 filters-width"
    :label="inputLabel"
    :label-for="inputId"
  >
    <b-input-group class="input-group-merge w-100">
      <b-form-input
        :value="selectedDate"
        :id="inputId"
        :class="inputId"
        :placeholder="inputPlaceholder"
      ></b-form-input>
      <b-input-group-append is-text>
        <template v-if="selectedDate">
          <feather-icon
            @click="clearDateFilter"
            class="cursor-pointer"
            icon="XIcon"
            size="18"
          />
        </template>
        <template v-else>
          <feather-icon class="cursor-pointer" icon="ClockIcon" size="18" />
        </template>
        <date-picker
          v-model="selectedDate"
          :customInput="`.${inputId}`"
          locale="en"
          format="YYYY-MM-DD"
          type="date"
        ></date-picker>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>
<script>
export default {
  emits: ["getSelectedDate"],
  watch: {
    selectedDate: {
      handler(val) {
        this.$emit("getSelectedDate", this.selectedDate);
      },
    },
  },
  props: {
    inputLabel: {
      type: [String, null],
      default: "Date",
    },
    inputPlaceholder: {
      type: [String, null],
      default: "Date",
    },
    inputId: {
      type: String,
      required: false,
      default: "dateFilter",
    },
  },
  data() {
    return {
      selectedDate: null,
    };
  },
  methods: {
    clearDateFilter() {
      this.selectedDate = null;
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
};
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
</script>
